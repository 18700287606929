import React from 'react';
import Container from "../ui/container/container";
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import BulitpointsComponent from "./bulitpoints.component";
import SliderComponent from "./slider.component";
import T from "./t.component";

const StorageComponent = () => {
    return (
        <>
            <Container name={'slider'}>
                <Section margin={true} direction={'vertical'}>
                    <Column col={12}>
                        <h2><T>Почему Мы</T></h2>
                    </Column>
                </Section>
            </Container>
            <Container>
                <Section>
                    <Column className={'w100'} col={6}>
                        <BulitpointsComponent/>
                    </Column>
                    <Column className={'w100 mt2'} col={6}>
                        <SliderComponent />
                    </Column>
                </Section>
            </Container>
        </>
    );
};

export default StorageComponent;