import React from 'react';
import classes from './headerMenu.module.sass';

const HeaderMenu = React.forwardRef(({active, children}, ref) => {
    return (
        <div ref={ref} className={`${active ? classes.headerMenuActive : ''} ${classes.headerMenu}`}>
            {children}
        </div>
    );
});

export default HeaderMenu;