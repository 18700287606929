import {$host} from "../http/axios";

export class SiteMethods {
    static getLangs = async () => {
        const {data} = await $host.post(`/language/all`);
        return data;
    }

    static getPages = async (isoCode) => {
        const {data} = await $host.post(`/page/get/lang/${isoCode}`);
        return data;
    }

    static getOnePage= async (slug, isoCode) => {
        const {data} = await $host.post(`/page/get/slug/${slug}/lang/${isoCode}`);
        return data;
    }

    static getStocks = async () => {
        const {data} = await $host.post(`/stock/all`);
        return data;
    }

    static getUnits= async (isoCode) => {
        const {data} = await $host.post(`/unit/get/lang/${isoCode}`);
        return data;
    }

    static getConfig= async () => {
        const {data} = await $host.post(`/config/get`);
        return data;
    }

    static getUnitTypes= async (isoCode) => {
        const {data} = await $host.post(`/unitTypes/get/lang/${isoCode}`);
        return data;
    }

    static getSlider= async () => {
        const {data} = await $host.post(`/slider/all`);
        return data;
    }

    static getAdvantages= async (isoCode) => {
        const {data} = await $host.post(`/advantage/get/lang/${isoCode}`);
        return data;
    }

    static getNews= async (isoCode) => {
        const {data} = await $host.post(`/news/get/lang/${isoCode}`);
        return data;
    }

    static getOneNews = async (slug, isoCode) => {
        const {data} = await $host.post(`/news/get/slug/${slug}/lang/${isoCode}`);
        return data;
    }

    static getWebPages= async (isoCode) => {
        const {data} = await $host.post(`/web_pages/get/lang/${isoCode}`);
        return data;
    }

    static getOneWebPage = async (slug, isoCode) => {
        const {data} = await $host.post(`/web_pages/get/slug/${slug}/lang/${isoCode}`);
        return data;
    }

    static getStatic= async (isoCode) => {
        const {data} = await $host.post(`/static/lang/all/${isoCode}`);
        return data;
    }

    static addStatic = async (formData) => {
        const {data} = await $host.post(`/static/add`, formData);
        return data;
    }
}