import React from 'react';
import classes from './header.module.sass';
import {useLocation} from 'react-router-dom';
import Container from "../container/container";
import Column from "../column/column";
import Button from "../button/button";
import HeaderMenuComponent from "../../components/headerMenu.component";
import CustomLink from "../customLink/customLink";
import CustomImg from "../customImg/customImg";
import T from "../../components/t.component";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {SiteMethods} from "../../methods/site.methods";

const Header = ({logo, langs, contacts, toScroll}) => {
    const {config} = React.useContext(Context);
    const location = useLocation();

    const [open, setOpen] = React.useState(false);
    const menuRef = React.useRef(null);
    const menuWrapRef = React.useRef(null);

    React.useEffect(() => {
        SiteMethods.getConfig().then(res => {
            config.setConfig(res.data);
        })
    }, [])


    const activeOpen = () => {
        setOpen(() => !open);
    }

    React.useEffect(()  =>  {
        const onClick = e => (menuRef.current.contains(e.target) || menuWrapRef.current.contains(e.target)) || setOpen(() => false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
        // eslint-disable-next-line
    }, []);

    return (
        <div id={'header'} className={classes.header}>
            <Container>
                <div className={classes.headerWrapper}>
                    <Column col={3}>
                        {
                            location.pathname === '/' || location.pathname === `/${localStorage.getItem('lang')}/`
                                ?
                                <div className={classes.logo} onClick={() => toScroll('header')}>
                                    <img src={logo} alt="logo"/>
                                </div>
                                :
                                <div className={classes.logo}>
                                    <CustomLink to={'/'}><CustomImg src={logo} alt="logo"/></CustomLink>
                                </div>
                        }

                    </Column>
                    <Column col={2}>
                        <div className={classes.langs}>
                            {langs}
                        </div>
                    </Column>
                    <Column col={4}>
                        <div className={classes.contacts}>
                            {contacts}
                        </div>
                    </Column>
                    <Column col={3}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent:'flex-end', gap: '1rem'}}>
                            <Button ref={menuRef} onClick={activeOpen} variant={!open ? 'menu' : 'close'} />
                            <Button
                                url={`https://ambar.kinnovis.com/1307458161?unitTypeCategoryId=1194937653&lang=${localStorage.getItem('lang')}`}
                                style={{textDecoration: 'none'}}
                                className={'hidden_mobile'}
                                variant={'border_icon_link'}
                                target={'_blank'}
                                icon={`${process.env.PUBLIC_URL}/images/arrow_up.svg`}
                            >
                                <T>Rezervē tagad</T>
                            </Button>
                        </div>
                    </Column>
                </div>
            </Container>
            {
                <HeaderMenuComponent activeOpen={activeOpen} langs={langs} contacts={contacts} active={open} ref={menuWrapRef} toScroll={toScroll}/>
            }
        </div>
    );
};

export default observer(Header);