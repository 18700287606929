import React from 'react';
import HeaderComponent from "../components/header.component";
import FooterComponent from "../components/footer.component";
import {useScroll} from "../hooks/useScroll";
import {useScrollToTop} from "../hooks/useScrollToTop";
import WebTextComponent from "../components/webText.component";
import {observer} from "mobx-react-lite";


const WebTextPage = () => {
    const [Element, scrollToElement] = useScroll();

    useScrollToTop();

    return (
        <>
            <Element name="header">
                <HeaderComponent/>
            </Element>
            <WebTextComponent />
            <FooterComponent toScroll={scrollToElement} />
        </>
);
};

export default observer(WebTextPage);