import React from 'react';
import Loader from "../ui/loader/loader";
import {observer} from "mobx-react-lite";
import {SiteMethods} from "../methods/site.methods";
import {toJS} from "mobx";
import {Context} from "../index";

const T = ({children}) => {
    const {st} = React.useContext(Context);
    const [translate, setTranslate] = React.useState(children);
    const [loading, setLoading] = React.useState(true);

    React.useMemo(() => {
        if (Object.keys(toJS(st.isStatic)).length > 0 && !toJS(st.isStatic)[children]){
            console.log(toJS(st.isStatic));
            setTranslate(children);
            const formData = new FormData();
            formData.append('name', children);
            formData.append('field', children);
            setTimeout(() => {
                SiteMethods.addStatic(formData).then().catch(error => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                })
            }, 100);
        } else {
            setTranslate(st.isStatic[children]);
        }
        // setTranslate(children);

        setLoading(false)
    }, [children, st.isStatic]);


    return (
        loading
            ?
            <Loader size="small"/>
            :
            translate
    );
};

export default observer(T);