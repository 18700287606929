import React from 'react';
import ReactDOM from 'react-dom/client';
import './reset.sass';
import {RouterProvider} from "react-router-dom";
import {routes} from "./routes";
import StockStore from "./store/stock.store";
import ConfigStore from "./store/config.store";
import StaticStore from "./store/static.store";

export const Context = React.createContext(null);

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <Context.Provider value={{
        stock: new StockStore(),
        config: new ConfigStore(),
        st: new StaticStore(),
    }}>
        <RouterProvider router={routes} />
    </Context.Provider>
);