import React from 'react';
import classes from './module.module.sass';

const Module = ({variant, title, children}) => {
    return (
        <div className={`${classes.module} ${variant === 'dark' ? classes.dark : ''}`}>
            <h3>{title}</h3>
            {children}
        </div>
    );
};

export default Module;