import React from 'react';
import Column from "../ui/column/column";
import Container from "../ui/container/container";
import Section from "../ui/section/section";
import Line from "../ui/line/line";
import {SiteMethods} from "../methods/site.methods";
import {useLocation} from "react-router-dom";
import ErrorPage from "../pages/error.page";
import parser from "html-react-parser";
import {observer} from "mobx-react-lite";
import ErrorComponent from "./error.component";
import Seo from "./seo";

const WebTextComponent = () => {
    const [data, setData] = React.useState({});
    const [error, setError] = React.useState(false)
    const location = useLocation();

    React.useEffect(() => {
        SiteMethods.getOneWebPage(location.pathname.split('/').at(-1), localStorage.getItem('lang')).then((data) => {
            setData(() => data.data);
        }).catch((err) => {
            if (err) {
                setError(() => true);
            }
        })
    }, [location.pathname]);



    if (error) {
        return <ErrorComponent />
    }

    return (
        <>
            <Seo page={data} />
            <Container className={'oneNews'} >
                <Section margin={true}>
                    <Column col={12}>
                        <h1>{data.name}</h1>
                    </Column>
                    <Column col={12}>
                        <Line />
                    </Column>
                    <Column className={'pt-all2 hidden_mobile'} col={6} />
                    <Column className={'pt-all2'}  col={6}>
                        {
                            data.text && parser(data.text)
                        }
                    </Column>
                </Section>
            </Container>
        </>
    );
};

export default observer(WebTextComponent);