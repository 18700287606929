import {makeAutoObservable} from "mobx";

export default class StockStore{

    constructor() {
        this._stock = [];
        makeAutoObservable(this);
    }

    setStock(data) {this._stock = data}

    get isStock() {return this._stock}
}