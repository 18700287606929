import React from 'react';
import SSlider from "../ui/slider/slider";
import {SiteMethods} from "../methods/site.methods";
import {useLocation} from "react-router-dom";

const slides = [
    {image: `${process.env.PUBLIC_URL}/images/1.jpg`, alt: '1'},
    {image: `${process.env.PUBLIC_URL}/images/2.jpg`, alt: '2'},
]

const SliderComponent = () => {
    const [datas, setDatas] = React.useState({});
    const location = useLocation()

    const getDatas = React.useCallback(() => {
        SiteMethods.getSlider().then(slider => {
            setDatas(() => slider.data);
        });
    }, []);

    React.useEffect(() => {
        getDatas();
    }, [location.pathname]);

    return (
        <SSlider slides={Object.values(datas)}/>
    );
};

export default SliderComponent;