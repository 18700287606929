import React from 'react';
import Contacts from "../ui/contacts/contacts";
import ContactItem from "../ui/contacts/contactItem";
import {SiteMethods} from "../methods/site.methods";
import {useLocation} from "react-router-dom";

const ContactsComponent = () => {
    const location = useLocation()
    const [contacts, setContacts] = React.useState([]);

    const getDatas = React.useCallback(() => {
        SiteMethods.getConfig().then(data => {
            setContacts(() => [data.data.phone, data.data.email]);
        });
    }, []);

    React.useEffect(() => {
        getDatas();
    }, [location.pathname]);

    return (
        <Contacts>
            {
                contacts.map((contact, index) => {
                    return (
                        <ContactItem
                            variant={contact.indexOf('@') !== -1 ? 'mailto' : 'tel'}
                            key={contact}
                        >
                            {contact.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, `$1 $2 $3 $4`)}
                        </ContactItem>
                    );
                })
            }
        </Contacts>
    );
};

export default ContactsComponent;