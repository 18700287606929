import React from 'react';
import HeaderMenu from "../ui/headerMenu/headerMenu";
import Container from "../ui/container/container";
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import Menu from "../ui/menu/menu";
import {Link, useLocation} from "react-router-dom";
import {menu} from "./footer.component";
import CustomLink from "../ui/customLink/customLink";
import T from "./t.component";

const HeaderMenuComponent = React.forwardRef(({activeOpen, langs, contacts, active, toScroll}, ref) => {
    const location = useLocation();
    const onClick = (element) => {
        toScroll(element, {
            duration: 1500,
            delay: 100,
        });
        activeOpen()
    }
    return (
        <HeaderMenu active={active} ref={ref}>
            <Container>
                <Section>
                    <Column className={'hidden_mobile'} col={3} />
                    <Column className={'w50'} col={3}>
                        <div className={'hidden_desktop contacts'}>
                            {langs}
                            {contacts}
                        </div>
                    </Column>
                    <Column className={'w50'} col={3}>
                        <Menu>
                                {
                                    menu.map(item => {
                                        if (location.pathname === '/' || location.pathname === `/${localStorage.getItem('lang')}/`) {
                                            if (item.scroll) {
                                                return (
                                                    <div className={'menu_link'} key={item.id} onClick={() => onClick(item.url)}>{item.title}</div>
                                                )
                                            } else {
                                                return (
                                                    <CustomLink key={item.id} to={item.url}>{item.title}</CustomLink>
                                                )
                                            }
                                        }
                                    })
                                }
                            {
                                location.pathname !== '/' && location.pathname !== `/${localStorage.getItem('lang')}/` &&
                                <>
                                    <CustomLink to={'/'}><T>Главная</T></CustomLink>
                                    <CustomLink to={'/news'}><T>Новости</T></CustomLink>
                                </>
                            }
                        </Menu>
                    </Column>
                    <Column className={'hidden_mobile'} col={3} />
                </Section>
            </Container>
        </HeaderMenu>
    );
});

export default HeaderMenuComponent;