import React from 'react';
import Langs from "../ui/langs/langs";
import LangItem from "../ui/langs/langItem";
import {SiteMethods} from "../methods/site.methods";
import {useLocation, useNavigate} from "react-router-dom";

const LangSwitcherComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [langs, setLangs] = React.useState([]);

    React.useEffect(() => {
        SiteMethods.getLangs().then(data => {
            setLangs(() => Object.values(data.data))
        })
    }, [location.pathname])

    const [currentLang, setCurrentLang] = React.useState(localStorage.getItem("lang") || "lv");

    React.useEffect(() => {
        if (localStorage.getItem("lang") === null) {
            localStorage.setItem("lang", "lv");
        }
    }, []);

    const switchLang = (lang) => {
        localStorage.setItem("lang", lang);
        setCurrentLang(() => lang);

        if (lang === 'lv') {
            navigate("/" + location.pathname);
        } else {
            if (location.pathname.split('/')[1].length === 2) {
                const url = '/' + lang  + location.pathname.slice(3)
                navigate(url);
                console.log(url, location.pathname);
            } else {
                navigate("/" + lang + location.pathname)
            }
        }
    }

    return (
        <Langs>
            {
                langs.sort((a, b) => Number(a.id) - Number(b.id)).map((lang) => {
                    if (lang.isActive) {
                        return (
                            <LangItem
                                onClick={() => switchLang(lang.isoCode)}
                                active={lang.isoCode.toLowerCase() === currentLang.toLowerCase()}
                                key={lang.id}
                            >
                                {lang.isoCode}
                            </LangItem>
                        );
                    }
                })
            }
        </Langs>
    );
};

export default LangSwitcherComponent;