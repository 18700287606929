import React from 'react';
import classes from './loader.module.sass';

const Loader = () => {
    return (
        <span className={classes.ldsRing}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    );
};

export default Loader;