import React from 'react';
import c from './banner.module.sass';
import Video from "../video/video";

const Banner = ({video, children}) => {
    return (
        <div className={`${c.banner} ${video ? c.bannerVideo : ''}`}>
            {
                video &&
                <Video
                    controls={false}
                    poster={process.env.PUBLIC_URL + '/videos/poster.jpg'}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    src={process.env.PUBLIC_URL + '/videos/video.mp4'}
                    type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
                />
            }
            {children}
        </div>
    );
};

export default Banner;