import React from 'react';
import classes from './card.module.sass';
import Button from "../button/button";
import {Link} from "react-router-dom";
import parser from 'html-react-parser'
import CustomLink from "../customLink/customLink";
import CustomImg from "../customImg/customImg";
import T from "../../components/t.component";

const Card = ({variant, size, size2, img, price, date, title, url, target, description}) => {

    switch (variant) {
        case 'news':
            return (
                <CustomLink className={classes.card + ' ' + classes.cardNews} to={url}>
                    <CustomImg width={1000} height={1000} src={img} alt={size}/>
                    <p>{date}</p>
                    <h3>{title}</h3>
                    <p className={classes.cardDescription}>{description}</p>
                    <Link to={url} target="_blank" rel="noopener noreferrer"><T>Читать</T></Link>
                </CustomLink>
            );
        case 'news_small':
            return (
                <CustomLink className={classes.card + ' ' + classes.cardSmall} to={url}>
                    <p>{date}</p>
                    <h4>{title}</h4>
                    <Link to={url} target="_blank" rel="noopener noreferrer"><T>Читать</T></Link>
                </CustomLink>
            );
        default:
            return (
                <CustomLink to={url} target={target} className={classes.card}>
                    <div className={classes.cardHeader}>
                        <p>{size}</p>
                        <p>{size2} m<sup>2</sup></p>
                    </div>
                    <CustomImg width={1000} height={1000} src={img} alt={size} />
                    <div className={classes.cardContent}>
                        <p><T>От</T></p>
                        <p>€ {price}</p>
                        <p>/ <T>в месяц</T></p>
                    </div>
                    <Button variant={'fill_with_icon'} icon={`${process.env.PUBLIC_URL}/images/arrow_up.svg`}><T>Забронировать</T></Button>
                    <div className={classes.cardFooter}>
                        {description ? parser(description) : null}
                    </div>
                </CustomLink>
            );
    }
};

export default Card;