import {makeAutoObservable} from "mobx";

export default class ConfigStore{
    constructor() {
        this._config = {};
        makeAutoObservable(this);
    }

    setConfig(data) {this._config = data}

    get isConfig() {return this._config}
}