import React from 'react';
import Column from "../ui/column/column";
import Card from "../ui/card/card";
import moment from "moment";

const CardSmallComponent = ({news}) => {
    return (
        <Column className={'w50'} col={4}>
            {
                news.sort((a, b) => a.id - b.id).map(item => {
                    return (
                        <Card
                            url={`/news/${item.slug}`}
                            key={item.id}
                            variant={'news_small'}
                            date={moment(Date(item?.createdAt)).format('MMMM DD, YYYY')}
                            title={item.name}
                        />
                    )
                })
            }
        </Column>
    );
};

export default CardSmallComponent;