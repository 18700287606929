import React from 'react';
import c from './section.module.sass';

const Section = ({margin, direction, position, height, children, className}) => {
    return (
        <section className={`${className} ${margin ? c.section_margin : ''} ${c.section} ${direction === 'vertical' ? c.section_vertical : ''} ${height === '100' ? c.section_full  : ''} ${position === 'end' ? c.positionEnd : ''}`}>
            {children}
        </section>
    );
};

export default Section;