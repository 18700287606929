import * as Scroll from "react-scroll";

export const useScroll = () => {
    const scroller = Scroll.scroller;
    const Element  = Scroll.Element;

    const scrollToElement = (e) => {
        scroller.scrollTo(e, {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -150,
        })
    }

    return [
        Element, scrollToElement
    ]
}