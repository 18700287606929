import React from 'react';
import Button from "../ui/button/button";
import Container from "../ui/container/container";
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import T from "./t.component";
import {useNavigate} from 'react-router-dom';

const ErrorComponent = () => {
    const navigate = useNavigate();
    const [timer, setTimer] = React.useState(5);

    const backToHome = () => {
        if (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'lv'){
            navigate(`/${localStorage.getItem('lang')}/`)
        } else {
            navigate('/')
        }
    }

    React.useEffect(() => {
        setTimeout(() => {
            if (timer !== 1) {
                setTimer((prev) => prev - 1)
            } else {
                backToHome();
            }
        }, 1000)
    }, [timer]);

    return (
        <Container>
            <Section>
                <Column col={12}>
                    <div style={{
                        height: '100vh',
                        display: 'flex',
                        gap: '1rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <h1 style={{textAlign: "center"}}><T>Страница не найдена</T></h1>
                        <p><T>Страница устарела или была удалена.</T></p>
                        <Button onClick={backToHome} style={{marginTop: '4rem'}} variant={'fill'}><T>На главную страницу</T> {timer}</Button>
                    </div>
                </Column>
            </Section>
        </Container>

    );
};

export default ErrorComponent;