import React from 'react';
import c from './container.module.sass';

const Container = ({className, height, children, width}) => {
    return (
        <section className={`${className} ${c.container} ${height ? c.container_full : ''} ${width ? c.container_full_width : ''}`}>
            {children}
        </section>
    );
};

export default Container;