import {makeAutoObservable} from "mobx";

export default class StaticStore{
    constructor() {
        this._static = [];
        this._loading = true;
        makeAutoObservable(this);
    }

    setStatic(data) {this._static = data}

    get isStatic() {return this._static}

    setLoading(data) {this._loading = data}

    get isLoading() {return this._loading}
}