import React from 'react';
import c from './block.module.sass';

const Block = ({children}) => {
    return (
        <div className={c.block}>
            {children}
        </div>
    );
};

export default Block;