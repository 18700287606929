import React from 'react';
import Header from "../ui/header/header";
import LangSwitcherComponent from "./langSwitcher.component";
import ContactsComponent from "./contacts.component";
import {observer} from "mobx-react-lite";
import {SiteMethods} from "../methods/site.methods";
import {useLocation} from "react-router-dom";
import {Context} from "../index";

const HeaderComponent = ({toScroll}) => {
    return (
        <Header
            logo={`${process.env.PUBLIC_URL}/images/ambar-logo.png`}
            langs={<LangSwitcherComponent />}
            contacts={<ContactsComponent />}
            toScroll={toScroll}
        />
    );
};

export default observer(HeaderComponent);