import React from 'react';
import {Helmet} from "react-helmet";

const Seo = ({page}) => {
    return (
        <Helmet>
            <title>{page.seoTitle ? page.seoTitle : page.name || page.title}</title>
            <link rel="canonical" href={window.location.href} />
            <link rel="preload" fetchPriority="high" as="image" href={process.env.REACT_APP_STATIC_URL + '/images/ambar192x192.png'} type="image/png" />
            <link rel='alternate' href={localStorage.getItem('lang') !== 'lv' && `/${localStorage.getItem('lang')}`} />
            <meta name="description" content={page.seoDescription ? page.seoDescription : page.description}/>
            <meta name="keywords" content={page.seoKeywords}/>
            <meta property="og:type" content="website"/>
            <meta property='og:title' content={page.seoTitle ? page.seoTitle : page.name || page.title}/>
            <meta property='og:image' content={page.seoImg ? process.env.REACT_APP_STATIC_URL + page.seoImg : process.env.REACT_APP_STATIC_URL + page.img}/>
            <meta property='og:description' content={page.seoDescription ? page.seoDescription : page.description}/>
            <meta property='og:url' content={window.location.href}/>
            <meta property="twitter:site" content="website"/>
            <meta property="twitter:url" content={window.location.href}/>
            <meta property="twitter:title" content={page.seoTitle ? page.seoTitle : page.name || page.title}/>
            <meta property="twitter:description" content={page.seoDescription ? page.seoDescription : page.description}/>
            <meta property="twitter:image" content={page.seoImg ? process.env.REACT_APP_STATIC_URL + page.seoImg : process.env.REACT_APP_STATIC_URL + page.img}/>
        </Helmet>
    );
};

export default Seo;