import React from 'react';
import Bulitpoints from "../ui/bulitpoints/bulitpoints";
import BulitpointItem from "../ui/bulitpoints/bulitpointItem";
import {SiteMethods} from "../methods/site.methods";
import {useLocation} from "react-router-dom";

const BulitpointsComponent = () => {
    const location = useLocation()
    const [advantages, setAdvantages] = React.useState({});

    const getAdvantages = React.useCallback(() => {
        SiteMethods.getAdvantages(localStorage.getItem('lang')).then(advantages => {
            setAdvantages(() => advantages.data);
        });
    }, []);

    React.useEffect(() => {
        getAdvantages();
    }, [location.pathname]);

    return (
        <Bulitpoints>
            {
                Object.values(advantages).map(item => {
                    if (item.isActive) {
                        return (
                            <BulitpointItem
                                key={item.id}
                                icon={
                                    item.resId ? process.env.REACT_APP_STATIC_ROUTES + `advantage/${item.resId}/` + item.img :
                                        process.env.REACT_APP_STATIC_ROUTES + `advantage/${item.id}/` + item.img
                                }
                            >
                                {item.name}
                            </BulitpointItem>
                        )
                    }
                })
            }
        </Bulitpoints>
    );
};

export default BulitpointsComponent;