import React from 'react';
import classes from './cookie.module.sass';
import Button from "../button/button";

const CookieSettings = React.forwardRef(({title, children, onAlloved, onDenied}, ref) => {

    return (
        <div className={classes.cookieSettings__wrapper}>
            <div className={classes.cookieSettings__container}>
                <h4>{title}</h4>

                <div>
                    {
                        children
                    }
                </div>

                <div className={classes.buttons}>
                    <div className={classes.button}>
                        <Button onClick={onAlloved} variant={'fill'}>Allow All</Button>
                        <Button color={'white'} onClick={onDenied} variant={'border'}>Allow Selection</Button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CookieSettings;