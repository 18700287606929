import React from 'react';
import c from './column.module.sass';

const columnVariants  =  {
    '1': 'col-1',
    '2': 'col-2',
    '3': 'col-3',
    '4': 'col-4',
    '5': 'col-5',
    '6': 'col-6',
    '7': 'col-7',
    '8': 'col-8',
    '9': 'col-9',
    '10': 'col-10',
    '11': 'col-11',
    '12': 'col-12',
}

const Column = ({col, children, style, className}) => {
    return (
        <div style={style} className={`${className} ${c[columnVariants[col]]}`}>
            {children}
        </div>
    );
};

export default Column;