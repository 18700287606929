import React from 'react';
import Column from "../ui/column/column";
import Container from "../ui/container/container";
import Section from "../ui/section/section";
import Line from "../ui/line/line";
import Button from "../ui/button/button";
import Card from "../ui/card/card";
import {useLocation} from "react-router-dom";
import CustomImg from "../ui/customImg/customImg";
import T from "./t.component";
import parser from "html-react-parser";
import moment from "moment";

const OneNewsComponent = ({datas, news}) => {
    const location = useLocation();

    console.log('n', datas);
    return (
        <Container className={'oneNews'} >
            <Section margin={true}>
                <Column col={6}>
                    <h1>{datas.name}</h1>
                    <p style={{marginBottom: '2rem'}}>{datas.description}</p>
                </Column>
                <Column col={6}>
                    {
                        datas.resId
                        ?
                            <CustomImg style={{height: 'auto'}} width={768} height={420} src={`${process.env.REACT_APP_STATIC_ROUTES}news/${datas.resId}/${datas.img}`} alt={datas.name}/>
                        :
                            <CustomImg style={{height: 'auto'}} width={768} height={420} src={`${process.env.REACT_APP_STATIC_ROUTES}news/${datas.id}/${datas.img}`} alt={datas.name}/>

                    }
                </Column>
                <Column col={12}>
                    <Line />
                </Column>
                <Column className={'pt-all2'} col={6}>
                    <div className={'mob'} style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                        <T>Share</T>:
                        <Button url={'https://www.linkedin.com/sharing/share-offsite/?url=https://ambar.lv' + location.pathname} variant={'social_icon_orange'} icon={`${process.env.PUBLIC_URL}/images/in.svg`}/>
                        <Button url={'https://www.facebook.com/sharer/sharer.php?u=#https://ambar.lv' + location.pathname} variant={'social_icon_orange'} icon={`${process.env.PUBLIC_URL}/images/face.svg`}/>
                    </div>
                </Column>
                <Column className={'pt-all2'}  col={6}>
                    {
                        datas.text &&  parser(datas.text)
                    }

                </Column>
                <Column col={12}>
                    <Line />
                </Column>
                <Column col={12}>
                    <h2><T>Новости</T></h2>
                </Column>
                {
                    news.map(item => {
                        return (
                            <Column className={'w50'} col={4}>
                                <Card
                                    variant={'news'}
                                    url={`/news/${item.slug}`}
                                    date={moment(Date(item?.createdAt)).format('MMMM DD, YYYY')}
                                    title={item?.name}
                                    description={item?.description}
                                    img={
                                        item.resId
                                        ?
                                        process.env.REACT_APP_STATIC_ROUTES + `news/${item?.resId}/` + item?.img
                                        :
                                        process.env.REACT_APP_STATIC_ROUTES + `news/${item?.id}/` + item?.img

                                    }
                                />
                            </Column>
                        )
                    })
                }
            </Section>
        </Container>
    );
};

export default OneNewsComponent;