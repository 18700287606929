import React from 'react';
import Button from "../button/button";
import classes from './backToTop.module.sass';
import T from "../../components/t.component";

const BackToTop = ({onClick, className}) => {
    return (
        <div
            className={className + ' ' + classes.backToTop}
            onClick={onClick}
        >
            <T>В начало</T>
            <Button
                style={{borderRadius: '3rem'}}
                variant={'social_icon_btn'}
                icon={`${process.env.PUBLIC_URL}/images/chevron_light.svg`}
            />
        </div>

    );
};

export default BackToTop;