import React from 'react';
import Column from "../ui/column/column";
import Container from "../ui/container/container";
import Section from "../ui/section/section";
import Line from "../ui/line/line";
import {SiteMethods} from "../methods/site.methods";
import {useLocation} from "react-router-dom";
import parser from "html-react-parser";
import ErrorComponent from "./error.component";

const TextComponent = () => {
    const [data, setData] = React.useState({});
    const [error, setError] = React.useState(false)
    const location = useLocation();

    React.useEffect(() => {
        SiteMethods.getOnePage(location.pathname.split('/').at(-1), localStorage.getItem('lang')).then((data) => {
            setData(() => data.data);
        }).catch((err) => {
            if (err) {
                setError(() => true);
            }
        })
    }, [location.pathname]);

    if (error) {
        return <ErrorComponent />
    }

    return (
        <Container className={'oneNews'} >
            <Section margin={true}>
                <Column col={12}>
                    <h1>{data.title}</h1>
                </Column>
                <Column col={12}>
                    <Line />
                </Column>
                <Column className={'pt-all2 hidden_mobile'} col={6} />
                <Column className={'pt-all2'}  col={6}>
                    {
                        data.content && parser(data.content)
                    }
                </Column>
            </Section>
        </Container>
    );
};

export default TextComponent;