import React from 'react';
import classes from './cookie.module.sass';
import Button from "../button/button";

const Cookie = ({title, children, onAlloved, onDenied, onLink}) => {
    return (
        <div className={classes.cookie}>
            <div className={classes.text}>
                <h4>{title}</h4>
                {children}
            </div>
            <div className={classes.buttons}>
                <div className={classes.button}>
                    <Button variant={'fill'} onClick={onAlloved}>Allow All</Button>
                    <Button color={'white'} variant={'border'} onClick={onDenied}>Deny</Button>
                </div>
                <Button color={'white'} onClick={onLink} variant={'border'}>Manage Preferences</Button>
            </div>
        </div>
    );
};

export default Cookie;