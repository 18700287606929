import axios from "axios";

const headers = {
    'Content-Security-Policy': 'localhost:7000',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': 'http://localhost:7000'
}

const $host_bot = axios.create({
    baseURL: process.env.REACT_APP_HOST_BOT,
});

const $host = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers
});

export {
    $host,
    $host_bot
};