import React from 'react';
import classes from './slider.module.sass';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../button/button";

const PrewArrow = (props) => {
    const {style, onClick} = props;

    return (
        <Button style={{...style}} variant={'slider'} onClick={onClick} icon={`${process.env.PUBLIC_URL}/images/chevron_left.svg`} />
    )
}

const NextArrow = (props) => {
    const {style, onClick} = props;

    return (
        <Button className={classes.next_arrow} style={{...style}} variant={'slider'} onClick={onClick} icon={`${process.env.PUBLIC_URL}/images/chevron_right.svg`} />
    )
}

const SSlider = ({slides}) => {
    const [currentSlide, setCurrentSlide] = React.useState(1);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        //autoplay: true,
        fade: true,
        waitForAnimate: false,
        nextArrow: <PrewArrow />,
        prevArrow: <NextArrow />,
        afterChange: function(i) {
            setCurrentSlide(() => i + 1)
        }
    };

    return (
        <div className={classes.slider}>
            <Slider {...settings}>
                {
                    slides.map(item => {
                        if (item.isActive) {
                            return (
                                <div className={classes.sliderItem} key={item.id}>
                                    <img src={process.env.REACT_APP_STATIC_ROUTES + `slider/${item.id}/` + item.img} alt={item.name}/>
                                </div>
                            )
                        }
                    })
                }
            </Slider>
            <div className={classes.sliderCounter}>
                {currentSlide} / {slides.length}
            </div>
        </div>
    );
};

export default SSlider;