import React from 'react';
import {useLocation} from "react-router-dom";
import HomePage from "./pages/home.page";
import NewsPage from "./pages/news.page";
import OneNewsPage from "./pages/oneNews.page";
import TextPage from "./pages/text.page";
import ErrorPage from "./pages/error.page";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {SiteMethods} from "./methods/site.methods";
import Loader from "./ui/loader/loader";
import CustomImg from "./ui/customImg/customImg";
import WebTextPage from "./pages/webText.page";
const App = () => {
    const location = useLocation();
    const {st} = React.useContext(Context);
    const [lang, setLang] = React.useState('');
    const [main, setMain] = React.useState(false);
    const [sub, setSub] = React.useState(false);
    const [subSub, setSubSub] = React.useState(false);
    const [error, setError] = React.useState(false);

    /****
     * Определяем передан ли язык - начало
     ***/

    React.useEffect(() => {
        const parseLocation = location.pathname.split('/');

        if (
            parseLocation[1].length === 0
        ) {
            setLang(() => 'lv');
            localStorage.setItem('lang', 'lv');
            setMain(() => true);
            setSub(() => false);
            setSubSub(() => false);
            setError(() => false);
        } else if (
            parseLocation[1].length === 2
        ) {
            setLang(() => parseLocation[1]);
            localStorage.setItem('lang', parseLocation[1]);
            if (!parseLocation[2] || parseLocation[2].length === 0) {
                setMain(() => true);
                setSub(() => false);
                setSubSub(() => false);
                setError(() => false);
            } else if (parseLocation[2] && parseLocation[2].length > 0) {
                if (!parseLocation[3] || parseLocation[3].length === 0) {
                    setMain(() => false);
                    setSub(() => true);
                    setSubSub(() => false);
                    setError(() => false);
                } else if (parseLocation[3] && parseLocation[3].length > 0){
                    if (!parseLocation[4] || parseLocation[4].length === 0) {
                        setMain(() => false);
                        setSub(() => false);
                        setSubSub(() => true);
                        setError(() => false);
                    } else if (parseLocation[4] && parseLocation[4].length > 0) {
                        setError(() => true);
                        setSub(() => false);
                        setSubSub(() => false);
                        setMain(() => false);
                    }
                }
            }
        } else if (parseLocation[1].length > 2) {
            setLang(() => 'lv');
            if (!parseLocation[2] || parseLocation[2].length === 0) {
                setMain(() => false);
                setSub(() => true)
                setSubSub(() => false);
                setError(() => false);
            } else if (!parseLocation[3] || (!parseLocation[3].length === 0)) {
                setMain(() => false);
                setSub(() => false);
                setSubSub(() => true);
                setError(() => false);
            } else if (parseLocation[3] && parseLocation[3].length > 0) {
                setError(() => true);
                setSub(() => false);
                setSubSub(() => false);
                setMain(() => false);
            }

        }
        }, [location.pathname]
    );

    /****
     * Определяем передан ли язык - конец
     ***/

    React.useEffect(() => {
        st.setLoading(true)
        SiteMethods.getStatic(localStorage.getItem("lang")).then(data => {
            st.setStatic(data.data)
        }).finally(() => st.setLoading(false));
    }, [location.pathname, st]);

    if (st.isLoading) {
        return (
            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', height: '100vh', width: '100vw'}}>
                <CustomImg src={process.env.PUBLIC_URL + '/images/ambar192x192.png'} alt="logo" style={{width: '100px', height: '100px'}}/>
                <Loader />
            </div>
        )
    }

    if (main) {
        return (
            <HomePage lang={lang} />
        );
    }

    if (sub) {
        if (location.pathname.split('/')[1] === 'news' || location.pathname.split('/')[2] === 'news') {
            return (
                <NewsPage lang={lang} />
            )
        } else {
            return (
                <TextPage lang={lang} />
            )
        }
    }

    if (subSub) {
        if (location.pathname.split('/')[1] === 'news' || location.pathname.split('/')[2] === 'news') {
            return (
                <OneNewsPage lang={lang} />
            )
        }
        else if (location.pathname.split('/')[1] === 'self_storage' || location.pathname.split('/')[2] === 'self_storage') {
            return (
                <WebTextPage lang={lang} />
            )
        }
    }

    if (error) {
        return (
            <ErrorPage lang={lang} />
        )
    }
};

export default observer(App);