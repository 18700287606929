import React from 'react';
import classes from './bulitpoints.module.sass';

const BulitpointItem = ({children, icon}) => {
    return (
        <div className={classes.bulitpointItem}>
            <img width={48} height={48} src={icon} alt={children} />
            {children}
        </div>
    );
};

export default BulitpointItem;