import React from 'react';
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import Card from "../ui/card/card";
import Container from "../ui/container/container";
import {SiteMethods} from "../methods/site.methods";
import moment from "moment";
import T from "./t.component";
import {useLocation} from "react-router-dom";
import Seo from "./seo";

const NewsComponent = () => {

    const location = useLocation();
    const [datas, setDatas] = React.useState({});

    const loadingDatas = React.useCallback(() => {
        SiteMethods.getNews(localStorage.getItem('lang')).then((data) => {
            setDatas(() => data.data);
        })
    }, []);

    React.useEffect(() => {
        loadingDatas()
    }, [location.pathname]);

    return (
        <>
            <Seo page={datas} />
            <Container>
                <Section margin={true}>
                    <Column col={12}>
                        <h2><T>Новости</T></h2>
                    </Column>

                    {
                        Object.values(datas).sort((a, b) => a.id - b.id).map((item) => {
                            if (item.isActive) {
                                return (
                                    <Column className={'w50'} col={4}>
                                        <Card
                                            variant={'news'}
                                            url={`/news/${item?.slug}`}
                                            date={moment(Date(item?.createdAt)).format('MMMM DD, YYYY')}
                                            title={item?.name}
                                            description={item?.description}
                                            img={item.resId
                                                ?
                                                process.env.REACT_APP_STATIC_ROUTES + `news/${item?.resId}/` + item?.img
                                                :
                                                process.env.REACT_APP_STATIC_ROUTES + `news/${item?.id}/` + item?.img
                                            }
                                        />
                                    </Column>
                                )
                            }
                        })
                    }
                </Section>
            </Container>
        </>
    );
};

export default NewsComponent;