import React from 'react';
import classes from './footer.module.sass';

const Footer = ({children}) => {
    return (
        <div className={classes.footer + ' foot'}>
            {children}
        </div>
    );
};

export default Footer;