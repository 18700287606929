import React from 'react';
import c from './buttonBox.module.sass';

const ButtonBox = ({children}) => {
    return (
        <div className={c.buttonBox}>
            {children}
        </div>
    );
};

export default ButtonBox;