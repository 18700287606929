import React from 'react';
import c from './button.module.sass';
import CustomLink from "../customLink/customLink";
import CustomImg from "../customImg/customImg";

export const buttonVariants = {
    fill: 'fill',
    border: 'border',
    border_link: 'border_link',
    link: 'link',
    icon: 'icon',
    fill_with_icon: 'fill_with_icon',
    border_icon: 'border_icon',
    social_icon: 'social_icon',
    social_icon_btn: 'social_icon_btn',
    social_icon_orange: 'social_icon_orange',
    menu: 'menu',
    slider: 'slider',
    close: 'close',
    fill_with_icon_link: 'fill_with_icon_link',
    border_icon_link: 'border_icon_link',
}

const Button = React.forwardRef(({variant, icon, color, children, onClick, className, style, url, target}, ref) => {
    switch(variant){
        case buttonVariants.menu:
            return <button ref={ref} style={style} onClick={onClick} className={`${className} ${c.btn} ${c.menu}`}><span></span></button>;
        case buttonVariants.close:
            return <button ref={ref} style={style} onClick={onClick} className={`${className} ${c.btn} ${c.menu} ${c.close}`}><span></span></button>;
        case buttonVariants.slider:
            return <button style={style} onClick={onClick} className={`${className} ${c.btn} ${c.slider}`}>
                <CustomImg src={icon} alt={children} width={24} height={24} />
            </button>;
        case buttonVariants.fill:
            return <button style={style} onClick={onClick} className={`${className} ${c.btn} ${c.fill}`}>{children}</button>;
        case buttonVariants.border:
            return <button style={style} onClick={onClick} className={`${className} ${c.btn} ${c.border} ${color === 'white' ? c.white : ''}`}>{children}</button>;
        case buttonVariants.border_link:
            return <CustomLink to={url} target={target} style={style} onClick={onClick} className={`${className} ${c.btn} ${c.border} ${color === 'white' ? c.white : ''}`}>{children}</CustomLink>;
        case buttonVariants.link:
            return <button style={style} onClick={onClick} className={`${className} ${c.btn} ${c.link}`}>{children}</button>;
        case buttonVariants.icon:
            return <button style={style} onClick={onClick} className={`${className} ${c.btn} ${c.icon}`}>{children}</button>;
        case buttonVariants.fill_with_icon:
            return <button style={style} onClick={onClick} className={`${className} ${c.btn} ${c.fill}`}>
                {children}
                <CustomImg src={icon} alt={children} width={16} height={16} />
            </button>;
        case buttonVariants.fill_with_icon_link:
            return <CustomLink to={url} target={target} style={style} onClick={onClick} className={`${className} ${c.btn} ${c.fill}`}>
                {children}
                <CustomImg src={icon} alt={children} width={16} height={16} />
            </CustomLink>;
        case buttonVariants.border_icon:
            return <button style={style} onClick={onClick} className={`${className} ${c.btn} ${c.border_icon}`}>
                {children}
                <CustomImg src={icon} alt={children} width={16} height={16}/>
            </button>;
        case buttonVariants.border_icon_link:
            return <CustomLink to={url} target={target} style={style} onClick={onClick} className={`${className} ${c.btn} ${c.border_icon}`}>
                {children}
                <CustomImg src={icon} alt={children} width={16} height={16}/>
            </CustomLink>;
        case buttonVariants.social_icon:
            return <CustomLink to={url} target={"_blank"} style={style} onClick={onClick} className={`${className} ${c.btn} ${c.social_icon}`}>
                <CustomImg src={icon} alt={children} width={16} height={16}/>
            </CustomLink>;
        case buttonVariants.social_icon_btn:
            return <button style={style} onClick={onClick} className={`${className} ${c.btn} ${c.social_icon}`}>
                <CustomImg src={icon} alt={children} width={16} height={16}/>
            </button>;
        case buttonVariants.social_icon_orange:
            return <CustomLink to={url} target={"_blank"} style={style} onClick={onClick} className={`${className} ${c.btn} ${c.social_icon} ${c.social_icon_orange}`}>
                <CustomImg src={icon} alt={children} width={16} height={16}/>
            </CustomLink>;
        default:
            break;
    }
});

export default Button;