import React, {useState} from 'react';
import HeaderComponent from "../components/header.component";
import BannerComponent from "../components/banner.component";
import FooterComponent from "../components/footer.component";
import MapComponent from "../components/map.component";
import StorageComponent from "../components/storage.component";
import CatalogComponent from "../components/catalog.component";
import HelpComponent from "../components/help.component";
import HomeNewsComponent from "../components/homeNews.component";
import {useScroll} from "../hooks/useScroll";
import {useScrollToTop} from "../hooks/useScrollToTop";
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router-dom";

const HomePage = () => {
    const [Element, scrollToElement] = useScroll();
    const location = useLocation();
    const [loc, setLoc] = useState('');

    React.useEffect(() => {
        if (location.search.length > 0) {
            setLoc(() => location.search.split('region=')[1])
        }
    }, [location]);

    useScrollToTop();

    return (
        <>
            <Element name="header">
                <HeaderComponent toScroll={scrollToElement}  />
            </Element>
            <Element name="banner">
                <BannerComponent filter={loc?.length > 0 ? loc : ''}/>
            </Element>
            <Element name="slider">
                <StorageComponent />
            </Element>
            <Element name="catalog">
                <CatalogComponent filter={loc?.length > 0  ? loc : ''} />
            </Element>
            <Element name="help">
                <HelpComponent />
            </Element>
            <Element name="map">
                <MapComponent  />
            </Element>
            <HomeNewsComponent />
            <FooterComponent toScroll={scrollToElement} />
        </>
);
};

export default observer(HomePage);