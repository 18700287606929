import React from 'react';
import classes from './langs.module.sass';

const LangItem = ({active, children, onClick}) => {
    return (
        <div onClick={onClick} className={`${active ? classes.active : ''} ${classes.langItem}`}>
            {children}
        </div>
    );
};

export default LangItem;