import React from 'react';
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import Module from "../ui/module/module";
import Container from "../ui/container/container";
import T from "./t.component";
import CustomImg from "../ui/customImg/customImg";

const HelpComponent = () => {
    return (
        <Container name={'help'}>
            <Section margin={true} direction={'vertical'}>
                <Column className={'w100'} col={9}>
                    <h2><T>Поможем с транспортировкой личных вещей</T></h2>
                </Column>
            </Section>
            <Section className={'mb1 dirCol'}>
                <Column col={6}>
                    <CustomImg style={{maxWidth: '100%', height: '100%', borderRadius: '.8rem'}} width={1000} height={1000} src={`${process.env.PUBLIC_URL}/images/2.jpg`} alt="Mantu pārvadāšana"/>
                </Column>
                <Column col={6}>
                    <Module title={<T>Перевозка вещей</T>}>
                        <p><T>Ambar предоставляет помощь в перевозке ваших вещей, обеспечивая максимальное удобство и безопасность при транспортировке. Мы предлагаем организацию транспортировки вашего имущества до и от наших складов.</T></p>
                    </Module>
                </Column>
            </Section>
            <div style={{margin: '.8rem 0'}}/>
            <Section className={'reverse2'}>
                <Column col={6}>
                    <Module title={<T>Погрузка и разгрузка</T>}>
                        <p><T>Для вашего удобства мы предоставляем отдельную услугу профессиональной загрузки и разгрузки вещей с использованием кара или погрузчика. Наши специалисты осуществят все необходимые работы, гарантируя бережное обращение с вашим имуществом.</T></p>
                    </Module>
                </Column>
                <Column col={6}>
                    <CustomImg style={{maxWidth: '100%', height: '100%', borderRadius: '.8rem'}} width={1000} height={1000} src={`${process.env.PUBLIC_URL}/images/1.jpg`} alt="Iekraušana un izkraušana"/>
                </Column>
            </Section>
        </Container>
    );
};

export default HelpComponent;