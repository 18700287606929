import React from 'react';
import classes from './contacts.module.sass';
import {Link} from "react-router-dom";

const ContactItem = ({variant, children}) => {
    return (
        <Link className={classes.contactItem} to={variant + ":" + children}>
            {children}
        </Link>
    );
};

export default ContactItem;