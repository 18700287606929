import React from 'react';
import Container from "../ui/container/container";
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import classes from "../ui/header/header.module.sass";
import {useLocation} from "react-router-dom";
import Button from "../ui/button/button";
import Footer from "../ui/footer/footer";
import BackToTop from "../ui/backToTop/backToTop";
import Menu from "../ui/menu/menu";
import CookieSettings from "../ui/cookie/cookieSettings";
import Cookie from "../ui/cookie/cookie";
import Checkbox from "../ui/checkbox/checkbox";
import T from "./t.component";
import moment from "moment";
import CustomLink from "../ui/customLink/customLink";
import CustomImg from "../ui/customImg/customImg";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {SiteMethods} from "../methods/site.methods";
import {toJS} from "mobx";
import parser from "html-react-parser";

const cookieSettings = [
    {id: 1, required: true,  check: true, name: 'Strictly required cookies', description: 'These cookies are crucial for site function and cannot be blocked. Blocking them might affect how our website works'},
    {id: 2, required: false,  check: false, name: 'Analytics', description: 'These cookies are crucial for site function and cannot be blocked. Blocking them might affect how our website works'},
    {id: 3, required: false,  check: true, name: 'Functionality Cookies', description: 'These cookies are crucial for site function and cannot be blocked. Blocking them might affect how our website works'},

]

export const menu = [
    {id: 1, title: <T>Зарезервировать хранилище</T>, url: 'banner', scroll: true},
    {id: 2, title: <T>Почему Мы</T>, url: 'slider', scroll: true},
    {id: 3, title: <T>Хранилища</T>, url: 'catalog', scroll: true},
    {id: 4, title: <T>Дополнительные услуги</T>, url: 'help', scroll: true},
    {id: 5, title: <T>Карта</T>, url: 'map', scroll: true},
    {id: 6, title: <T>Новости</T>, url: '/news', scroll: false},
]

const FooterComponent = ({toScroll}) => {
    const {config} = React.useContext(Context);
    const location = useLocation();

    const [cookie] = React.useState(localStorage.getItem('cookie-allowed'));
    const [cookieBanner, setCookieBanner] = React.useState(true);
    const [cSetting, setCSetting] = React.useState(false);
    const [cookieData] = React.useState(cookieSettings);
    const [pages, setPages] = React.useState([])
    const [webPages, setWebPages] = React.useState([])

    React.useEffect(() => {
        SiteMethods.getPages(localStorage.getItem('lang')).then(res => {
            setPages(() => Object.values(res.data))
        })
    }, [location.pathname]);

    React.useEffect(() => {
        SiteMethods.getWebPages(localStorage.getItem("lang")).then(data => {
            console.log(data.data)
            setWebPages(Object.values(data.data))
        });
    }, [location.pathname]);

    React.useEffect(()  => {
        if (cookie) {
            setCookieBanner(() => false)
        }
    }, [cookie])

    const allowedCookies  = ()  => {
        localStorage.setItem('cookie-allowed', String(Date.now()));
        setCookieBanner(() =>  false);
    }

    const deniedCookies  = ()  => {
        setCookieBanner(() =>  false);
    }

    const settingCookies = () => {
        setCSetting(()  =>  true);
    }

    const allowedCookiesAll =  ()  =>  {
        localStorage.setItem('cookie-allowed', String(Date.now()));
        setCookieBanner(() =>  false);
        setCSetting(() => false);
    }

    const deniedCookiesAll = () => {
        setCookieBanner(() => false);
        setCSetting(() => false);
    }

    const toggleCheckBox = (e) => {
        const row = cookieData.find(item => item.id === Number(e.target.id))
        console.log(row)
    }

    const onClick = (element) => {
        toScroll(element, {
            duration: 1500,
            delay: 100,
        });
    }

    return (
        <Footer>
            <Container>
                <Section>
                    <Column className={'w50'} col={4}>
                        {
                            location.pathname === '/'
                                ?
                                <div className={classes.logo}>
                                    <CustomImg src={`${process.env.PUBLIC_URL}/images/ambar-logo.png`} alt="logo"/>
                                </div>
                                :
                                <div className={classes.logo}>
                                    <CustomLink to={'/'}><CustomImg src={`${process.env.PUBLIC_URL}/images/ambar-logo.png`}
                                                        alt="logo"/></CustomLink>
                                </div>
                        }

                        <p><T>Rental of smart self-storage.</T> © {moment(Date.now()).format('YYYY')} <T>All rights reserved.</T></p>
                        <div className={'mb2 mob'} style={{display: 'flex', alignItems: 'center', gap: '1rem', marginTop: '4rem'}}>
                            {
                                Object.keys(config.isConfig).length > 0
                                &&
                                <>
                                {
                                    config.isConfig.share_in_url && <Button url={config.isConfig.share_in_url} target={'_blank'} variant={'social_icon'} icon={`${process.env.PUBLIC_URL}/images/in.svg`}/>
                                }
                                {
                                    config.isConfig.share_face_url && <Button url={config.isConfig.share_in_url} target={'_blank'} variant={'social_icon'} icon={`${process.env.PUBLIC_URL}/images/face.svg`}/>
                                }
                                </>

                            }
                        </div>
                    </Column>
                    <Column className={'w50'} col={3}>
                        <h4><T>Навигация</T></h4>
                        <Menu>
                            {
                                menu.map(item => {
                                    if (location.pathname === '/' || location.pathname === `/${localStorage.getItem('lang')}/`) {
                                        if (item.scroll) {
                                            return (
                                                <div className={'menu_link'} key={item.id} onClick={() => onClick(item.url)}>{item.title}</div>
                                            )
                                        } else {
                                            return (
                                                <CustomLink key={item.id} to={item.url}>{item.title}</CustomLink>
                                            )
                                        }
                                    }
                                })
                            }
                            {
                                location.pathname !== '/' && location.pathname !== `/${localStorage.getItem('lang')}/` &&
                                <>
                                    <CustomLink to={'/'}><T>Главная</T></CustomLink>
                                    <CustomLink to={'/news'}><T>Новости</T></CustomLink>
                                </>
                            }
                        </Menu>
                    </Column>
                    <Column className={'w50 mt2'} col={3}>
                        <h4><T>Статьи</T></h4>
                        <Menu>
                            {
                                webPages.map(item => {
                                    if (item.isActive) {
                                        return (
                                            <CustomLink style={{
                                                maxWidth: '100%',
                                                textDecoration: 'none',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }} key={item.id} to={`/self_storage/${item.slug}`}>{item.name}</CustomLink>
                                        )
                                    }
                                })
                            }
                        </Menu>
                    </Column>
                    <Column className={'w100 mt2'} col={2}>
                        <BackToTop onClick={() => toScroll('header')} className={'hidden_mobile'} />
                    </Column>
                </Section>
                <Section margin={true}>
                    <Column className={'w100'} col={12}>
                        <div className={'dirCol'} style={{display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                            {
                                pages.map(item => {
                                    if (item.isActive) {
                                        return (
                                            <CustomLink key={item.id} to={`/${item.slug}`}>{item.title}</CustomLink>
                                        )
                                    }
                                })
                            }
                        </div>

                    </Column>
                    <Column className={'w100 mt2 hidden_desktop'} col={12}>
                        <BackToTop onClick={() => toScroll('header')} />
                    </Column>
                </Section>
            </Container>
            {
                cookieBanner
                &&
                <>
                    <Cookie title={'We use cookies'} onAlloved={allowedCookies} onDenied={deniedCookies} onLink={settingCookies}>
                        {config.isConfig.seo_block_text && parser(config.isConfig.seo_block_text)}
                        <p>Please read our Privacy <CustomLink to={'/test1.html'}>Privacy Policy</CustomLink> <T>and</T> <CustomLink to={'/test2.html'}>Cookie Policy</CustomLink>.</p>
                    </Cookie>
                    {
                        cSetting
                        &&
                        <CookieSettings title={'Manage Cookies'} onAlloved={allowedCookiesAll} onDenied={deniedCookiesAll}>
                            {
                                cookieData.map(item =>
                                    <div key={item.id} className={'item'}>
                                        <div>
                                            <h5>{item.name}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                        <Checkbox htmlFor={item.id} onChange={toggleCheckBox} disabled={item.required} checked={item.check} />
                                    </div>
                                )
                            }
                        </CookieSettings>
                    }
                </>
            }
        </Footer>
    );
};

export default observer(FooterComponent);