import React from 'react';
import HeaderComponent from "../components/header.component";
import FooterComponent from "../components/footer.component";
import {useScroll} from "../hooks/useScroll";
import {useScrollToTop} from "../hooks/useScrollToTop";
import OneNewsComponent from "../components/oneNews.component";
import {useLocation} from "react-router-dom";
import {SiteMethods} from "../methods/site.methods";
import ErrorPage from "./error.page";
import Seo from "../components/seo";


const OneNewsPage = () => {
    const location = useLocation()
    const [Element, scrollToElement] = useScroll();

    const [datas, setDatas] = React.useState({});

    const [error, setError] = React.useState(false);

    const loadDatas = React.useCallback(() => {
        SiteMethods.getOneNews(location.pathname.split('/').at(-1), localStorage.getItem('lang')).then((res) => {
            setDatas(() => res.data);
        }).catch((error) => {
            if (error){
                setError(() => true);
            }
        });
    }, [])

    const [datasNews, setDatasNews] = React.useState([]);

    const loadingDatas = React.useCallback(() => {
        SiteMethods.getNews(localStorage.getItem('lang')).then((data) => {
            setDatasNews(() => Object.values(data.data)
                .sort((a, b) => b.id - a.id)
                .filter(item => item.isActive === true && item.slug !== location.pathname.split('/').at(-1)).slice(0, 3));
        }).catch((error) => {
            if (error){
                setError(() => true);
            }
        })
    }, []);

    React.useEffect(() => {
        loadDatas();
        loadingDatas()
    }, [location.pathname]);

    useScrollToTop();

    if (error) {
        return <ErrorPage />
    }

    return (
        <>
            <Seo page={datas} />
            <Element name="header">
                <HeaderComponent/>
            </Element>
            <OneNewsComponent datas={datas} news={datasNews}/>
            <FooterComponent toScroll={scrollToElement} />
        </>
);
};

export default OneNewsPage;